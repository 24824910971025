.StickyButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px;
    width: 90px;
    height: 90px;
    background-color: #FEB159;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
}

@media screen and (max-width: 768px) {
    .StickyButton {
        position: fixed;
        bottom: 20px;
        right: 40px;
        padding: 10px;
        width: 90px;
        height: 90px;
        background-color: #FEB159;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
    } 
}